import React, { Component } from 'react'
import { render } from 'react-dom'

import calendarize from 'calendarize';
import localforage from 'localforage';

import { Stitch, RemoteMongoClient, UserPasswordAuthProviderClient, UserPasswordCredential } from 'mongodb-stitch-browser-sdk'

// Initialize the App Client
const stitchClient = Stitch.initializeDefaultAppClient("easycalendar-lpylg");
// Get a MongoDB Service Client
const mongodb = stitchClient.getServiceClient(
  RemoteMongoClient.factory,
  "easycalendardb"
);
// Get a reference to the blog database
const db = mongodb.db("easycalendar");
const ObjectId = mongodb.ObjectID;

const curDateObj = new Date();
let currentDateStr = curDateObj.getFullYear() + '-' + (curDateObj.getMonth()+1) + '-' + curDateObj.getDate();

const logOutSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -3 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="jam-icon jam-0"><path d="M3.414 7.828h5.642a1 1 0 1 1 0 2H3.414l1.122 1.122a1 1 0 1 1-1.415 1.414L.293 9.536a.997.997 0 0 1 0-1.415L3.12 5.293a1 1 0 0 1 1.415 1.414L3.414 7.828zM13 0a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1z"></path></svg>';

class MyCalendar extends Component {

  constructor() {
    super();
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.setNoOverlay = this.setNoOverlay.bind(this);
  }
  setPosition(e) {
    this.setState({position: e.target.value});
  }
  setNoOverlay(e) {
    this.setState({noOverlay: e.target.checked});
  }
  toggleDrawer() {
    this.setState({open: !this.state.open});
  }
  closeDrawer() {
    this.setState({open: false});
  }
  onDrawerClose() {
    this.setState({open: false});
  }

  state = {
    currentDate: curDateObj,
    currentDateNum: curDateObj.getDate(),
    todayDateNum: curDateObj.getDate(),
    currentlyViewedMonthNum: curDateObj.getMonth(),
    currentlyViewedYearNum: curDateObj.getFullYear(),
    currentlyViewedMonth: curDateObj.toLocaleString('default', { month: 'short' }),
    currentDateStr: currentDateStr,
    currentInput: '',
    currentInputNumber: '',
    showNumberBox: false,
    openModal: false,
    allEvents: {},
    keyboardAdjustedHeight: 120,
    calendarUsrName: '',
    calendarPwd: '',
    currentCalendarBox: false,
    showLoginDiv: false,
    currentCalendarUser: false,
    currentlySelectedCalendar: 'main',
    currentlySelectedCalendars: {},
    currentlySelectedCalendarId: '',
    currentlyEnteredCalendarName: '',
    currentlySelectedColor: 'darkgrey',
    allUserCalendars: [],
    open: false,
    position: 'left',
    noOverlay: false,
    openNewCalendarModal: false,
    openDeleteCalendarModal: false,
    currentlyEditedEventId: false,
    allUserCalendarsId: {},
    showCalendars: false,
    loginText: 'Login'
  }

  componentDidMount() {
    let currentCalendarUser = false;
    localforage.getItem('tecaCalendarUser').then((val1) => {
      currentCalendarUser = val1;
      // console.log('currentCalendarUser', currentCalendarUser);
      this.setState({
        currentCalendarUser: currentCalendarUser
      });
      if(!val1){
        this.setState({
          showLoginDiv: true
        });
      }
      localforage.getItem('tecaCalendarCurCalendar').then((val) => {
        // console.log('tecaCalendarCurCalendar', val);
        let loadMainCalendar = 'loadMainCalendar';
        if(val && val.calendar){
          loadMainCalendar = false;
          this.setState({
            currentlySelectedCalendar: val.calendar,
            currentlySelectedCalendarId: val.calendar_id
          });
        }

        this.fetchUserCalendars(loadMainCalendar);
        // this.fetchAllEvents();
      });

      localforage.getItem('tecaCalendarShowCal').then((val) => {
        // console.log('tecaCalendarShowCal', val);
        if(val){
          this.setState({
            showCalendars: val
          });
        }
      });
    });
  }

  componentWillMount () {
  }

  componentWillUnmount () {
  }

  getCurrentDateStr = (dateNum) => {
    return this.state.currentDate.getFullYear() + '-' + (this.state.currentDate.getMonth()+1) + '-' + dateNum;
  }

  getCurrentDateStrByYear = (year) => {
    return year + '-' + (this.state.currentDate.getMonth()+1) + '-' + this.state.currentDateNum;
  }

  createNewUser = (data) => {
    let emailPasswordClient = stitchClient.auth.getProviderClient(UserPasswordAuthProviderClient.factory, 'userpass');

    emailPasswordClient.registerWithEmail(data.name, data.pwd)
      .then((registerResult) => {

        // console.log("Successfully sent account confirmation email!", registerResult);

        this.loginUser({
          name: data.name,
          pwd: data.pwd
        }, true);
      })
      .catch((err) => {
        console.error("Error registering new user:", err.message);
        this.setState({
          calendarLoginError: "Error registering new user: " + err.message
        });
      });
  }

  loginUser = (data, createFirstCalendar) => {
    // console.log('Trying login');

    this.setState({
      loginText: 'Logging in...'
    });

    const credential = new UserPasswordCredential(data.name, data.pwd);
    stitchClient.auth.loginWithCredential(credential)
      .then((authedUser) => {
        // console.log(`successfully logged in with id: ${authedUser.id}`);

        this.setState({
          loginText: 'Login'
        });

        localforage.setItem('tecaCalendarUser', authedUser.id);

        this.setState({
          currentCalendarUser: authedUser.id,
          calendarPwd: ''
        });

        if(createFirstCalendar){
          this.addUserCalendar({
            owner_id: authedUser.id,
            calendar: 'main'
          });
        } else {
          this.fetchUserCalendars('loadMainCalendar');
        }

      })
      .catch((err) => {
        this.setState({
          loginText: 'Login'
        });

        this.setState({
          calendarLoginError: "Error login: " + err.message
        });
      });
  }

  showEmptyIconScreen = () => {
    return (
    <div class="text-center sm-p-3 h-screen bg-gray-200">
      <div class="w-full h-screen float-left" style={{
        height: '100vh'
      }}>
        <div>
          <img src="logo512.png" class="m-auto" style={{
            width: 80,
            paddingTop: '40vh'
          }}></img>
        </div>
      </div>
    </div>
    );
  }

  generateLoginScreen = () => {
    return (
    <div class="text-center sm-p-3 h-screen bg-gray-200">
      <div class="w-full md:w-1/2 h-screen float-left" style={{
        height: '91vh'
      }}>
        <div>
          <img src="logo512.png" class="m-auto" style={{
            width: 80,
            paddingTop: '8vh'
          }}></img>
        </div>
        <div class="w-full md:w-1/2 sm:w-full bg-blue-300 pt-12 m-auto mt-8 shadow rounded" 
          onSubmit={() => {
            return false;
          }} 
        >
          <div class="m-2 p-2">
            <input class="w-full p-1 text-sm h-12 outline-none rounded" 
              type="email"
              placeholder="Enter email" 
              onChange={(e) => {
                this.setState({
                  calendarUsrName: e.target.value
                });
              }} 
              value={this.state.calendarUsrName} />
          </div>

          <div class="m-2 p-2">
            <input 
            class="w-full p-1 text-sm h-12 outline-none rounded"
            type="password" 
            placeholder="Enter password"
              onChange={(e) => {
                this.setState({
                  calendarPwd: e.target.value
                });
              }} 
              value={this.state.calendarPwd} />
          </div>

            <button class="w-1/2 bg-gray-200 rounded p-2 text-sm font-bold text-gray-800 m-2" 
              onClick={() => {
                this.loginUser({
                  name: this.state.calendarUsrName,
                  pwd: this.state.calendarPwd,
                });
              }}>
              {this.state.loginText}
            </button>

            <div class="p-2 pb-6 font-bold text-sm text-gray-100" 
            onClick={() => {
              // this.addNewCalendar({
              //   name: this.state.calendarUsrName,
              //   pwd: md5(this.state.calendarPwd),
              // });
              this.createNewUser({
                name: this.state.calendarUsrName,
                pwd: this.state.calendarPwd,
              });
            }} >Signup
            </div>

          { this.state.calendarLoginError &&  <div>{this.state.calendarLoginError}</div> }

          <div style={{
            clear: 'both'
          }}></div>
        </div>
      </div>
      <div class="w-1/2 h-auto md:h-screen p-2 float-left hidden md:block">
        <div class="font-bold p-3 mb-16"> Features 
        </div>
        <img src="tcal-1.png" class="m-auto w-full"></img>
      </div>
      <div class="w-full h-auto p-2 pt-2 bg-gray-200 float-left sm:block md:hidden">
        <div class="font-bold p-3 mb-2"> Features 
        <svg class="h-4 inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
        </div>
        <img src="tcal-2.jpeg" class="m-auto w-full"></img>
      </div>
    </div>
    );
  }

  generateRow = (someInput) => {

    console.log('someInput length', someInput.length);

    if(someInput.length < 6){
      someInput.push(['', '', '', '', '', '', '']);
    }

    return someInput.map((row, index) => {
      return(
        <div key={index} style={{
          display: 'flex',
          flexDirection: 'row',
          // borderWidth: 1,
          // borderColor: 'dimgrey',
          // borderLeftWidth: 0,
          marginBottom: 2
        }}>
          {this.generateCell(row)}
        </div>
      )
    })
  }

  generateRowHeader = () => {
    let daysOfTheWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return daysOfTheWeek.map((name, index) => {
      return(
        <div key={index} style={{
          display: 'flex',
            flexDirection: 'row',
            width: '14.4857%',
            textAlign: 'center',
            paddingLeft: 2,
            marginTop: 5,
            marginBottom: 5
        }} >
          <div class="w-full text-sm font-bold">
            {name}
          </div>
        </div>
      )
    })
  }

  generateCell = (row) => {
    return row.map((cell, index) => {
      let backgroundColor = 'white';
      if(cell == this.state.todayDateNum){
        backgroundColor = 'aliceblue';
      }
      let thisCellDateStr = this.getCurrentDateStr(cell);
      return(
        <div key={index} className="singlecell-tc" style={{
            // borderLeftWidth: 1,
            // borderBottomWidth: 0,
            width: '14.4857%',
            height: '13.4vh',
            marginRight: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: backgroundColor,
            overflowY: 'auto'
        }} onClick={() => {
          if(cell == 0){
            return;
          }
          let newDateStr = this.getCurrentDateStr(cell);
          this.setState({
            currentDateStr: newDateStr,
            openModal: !this.state.openModal,
            currentInput: '',
            currentInputNumber: '',
            currentlySelectedColor: 'darkgrey'
          });
        }}>
          <div style={{
            textAlign: 'center',
            paddingTop: 2,
            fontSize: 13,
            fontWeight: 'bold'
          }}>
            {cell || ''}
          </div>
          { this.state.allEvents[thisCellDateStr] && 
            this.generateCellEvents(this.state.allEvents[thisCellDateStr])
          }
        </div>
      )
    })
  }

  generateCellEvents = (cellEventsArr) => {
    return cellEventsArr.map((cur, index) => {
      let thisBgColor = 'darkgrey';
      let thisColor = 'white';
      if(cur.color){
        thisBgColor = cur.color
      }
      return (
        <div style={{
          width: '100%',
          backgroundColor: thisBgColor,
          marginBottom: 1
        }} key={index}>
          <div style={{
            fontSize: 10,
            lineHeight: '0.7rem',
            padding: 1,
            // wordBreak: 'break-all',
            fontWeight: 'bold',
            float: 'left',
            color: thisColor
          }} >{cur.event}
          </div>
        </div>
      )
    });
  }

  generateCellEventsBig = (cellEventsArr) => {
    return cellEventsArr.map((cur, index) => {
      return (
        <div class="mb-1 bg-gray-200 p-1 shadow rounded-sm m-1" key={index}>
          <div class="text-sm font-bold inline-block text-gray-800 w-10/12" >{cur.event}
          </div>
          {this.state.currentlySelectedCalendar && <button class="float-right text-sm mt-1 ml-2" onClick={() => {
              // console.log('trying to delete this event');
              this.deleteThisEvent(cur.id);
            }} >
              <svg
                class="text-gray-800"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="3 6 5 6 21 6" />
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
              </svg>
            </button>
          }
          {this.state.currentlySelectedCalendar && this.state.currentlySelectedCalendar != 'all' && <button class="float-right text-sm mt-1" onClick={() => {

            let currentInputNumber = 0;
            let currentInput = cur.event;

            if(cur.event && cur.event.indexOf('|') > 0){
              currentInputNumber = cur.event.split(' | ').pop();
              currentInput = cur.event.split('|')[0];
            }

            this.setState({
              currentInput: currentInput,
              currentInputNumber: currentInputNumber,
              currentlyEditedEventId: cur.id
            });
          }} >
            <svg
              class="text-gray-800"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
            </svg>
          </button>
          }
        </div>
      )
    });
  }

  renderLogoutIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z"/>
        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"/>
        <path d="M7 12h14l-3 -3m0 6l3 -3"/>
      </svg>
    );
  }

  renderPlusButton = () => {
    return (
      <svg className="jam-icon jam-2 float-left" stroke="white" fill="white" stroke-width="1px" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="16" height="16" preserveAspectRatio="xMinYMin">
        <path d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm7 11v4a1 1 0 0 1-2 0v-4H5a1 1 0 0 1 0-2h4V5a1 1 0 1 1 2 0v4h4a1 1 0 0 1 0 2h-4z"></path>
      </svg>
    );
  }

  renderDeleteButton = () => {
    return (
      <svg className="jam-icon jam-2 float-left" stroke="white" fill="white" stroke-width="1px" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="16" height="16" preserveAspectRatio="xMinYMin">
        <path d="M4 0h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm0 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm7.414 8l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10z"></path>
      </svg>
    );
  }

  addNewEvent = (data) => {
    if(data.eventStr == '' 
      || !this.state.currentCalendarUser 
      || !data.owner_id
      || !data.calendar_id ){

      return;
    }

    db.collection("userEvents")
    .insertOne(data)
    .then(() => {
      this.fetchAllEvents();
    });
  }

  editEvent = (data) => {
    if(data.eventStr == '' || !this.state.currentlyEditedEventId || !this.state.currentCalendarUser){

      return;
    }

    db.collection("userEvents").updateOne({
      "_id": {"$oid" : this.state.currentlyEditedEventId},
      // "owner_id": "5e5661b132f3ef8c168ca73e",
    }, {
      "$set": data
    }).then(() => {
      this.setState({
        currentlyEditedEventId: false
      });
      this.fetchAllEvents();
    }).catch((err) => {
      this.setState({
        currentlyEditedEventId: false
      });
      // console.log(err.message);
    });
  }

  deleteThisEvent = (id) => {
    if(!id || id == '' || !this.state.currentCalendarUser){

      return;
    }
    db.collection("userEvents").deleteOne({
      "_id": {"$oid" : id},
      // "owner_id": "5e5661b132f3ef8c168ca73e",
    }).then(() => {
      this.fetchAllEvents();
    }).catch((err) => {
      // console.log(err.message);
    });
  }
  
  deleteThisCalendar = (calendar) => {
    if(!calendar || calendar == '' || calendar == 'all' || calendar == 'main' || !this.state.currentCalendarUser){

      return;
    }

    // console.log('deleting ', calendar);

    let calendarId = this.state.allUserCalendarsId[calendar];
    if(!calendarId){

      // console.log('Calendar id not defined.');

      return false;
    }

    db.collection("users")
    .deleteOne({
      "calendar_id": calendarId
    })
    .then(() => {
      this.setState({
        currentlySelectedCalendar: 'main',
        openDeleteCalendarModal: false
      });
      this.fetchUserCalendars('loadMainCalendar');
      this.fetchAllEvents();
    });

    db.collection("userEvents").deleteMany({
      "calendar_id": calendarId
    }).then(() => {
      // console.log('deleted all events');
    }).catch((err) => {
      console.log(err.message);
    });
  }

  fetchAllEvents = () => {
    // return;
    if(!this.state.currentCalendarUser){
      return;
    }
    
    let curSelCal = this.state.currentlySelectedCalendar;

    let findObj = {
      // calendar: this.state.currentlySelectedCalendar,
      calendar_id: this.state.currentlySelectedCalendarId,
      // owner_id: this.state.currentCalendarUser
    };

    // console.log(this.state.allUserCalendarsId);
    // console.log('Fetching find', findObj);

    if(curSelCal == 'all'){
      curSelCal = '';
      findObj = {
        owner_id: this.state.currentCalendarUser
      }
    }

    let multipleSelectedCalendars = [];
    let copyCurrentlySelectedCalendars = this.state.currentlySelectedCalendars;
    Object.keys(copyCurrentlySelectedCalendars).forEach(function(key) {
      if(copyCurrentlySelectedCalendars[key]){
        multipleSelectedCalendars.push(key);
      }
    });

    if(multipleSelectedCalendars.length > 0){
      findObj.calendar_id = {"$in": multipleSelectedCalendars}
    }

    console.log(findObj);
    
    return db.collection("userEvents")
      .find(findObj, { limit: 1000 })
      .asArray()
      .then((responseJson) => {

        // console.log(responseJson);

        let formatedEvents = responseJson.reduce((prev, cur) => {
          if(typeof prev[cur.dateStr] == 'undefined'){
            prev[cur.dateStr] = [];
          }
          let fullEventStr = cur.eventStr;
          if(typeof cur.eventNumber != 'undefined' && cur.eventNumber && cur.eventNumber > 0){
            fullEventStr = cur.eventStr + ' | ' + cur.eventNumber;
          }

          prev[cur.dateStr].push({
            event: fullEventStr,
            id: cur['_id'].toString(),
            color: cur.eventColor || 'darkgrey'
          });
          return prev;
        }, {});

        // console.log(formatedEvents);

        this.setState({
          allEvents: formatedEvents,
          currentlyEditedEventId: false
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  devFetchAllEventsAndUpdate = () => {
    let findObj = {
      owner_id: this.state.currentCalendarUser
    };

    return db.collection("users")
      .find({
      }, { limit: 1000 })
      .asArray()
      .then((responseJson1) => {
        db.collection("userEvents")
          .find(findObj, { limit: 1000 })
          .asArray()
          .then((responseJson2) => {
    
          })
          .catch((error) => {
            console.error(error);
          });
      });
  }

  updateUserCalendarAndFetchAllCalendars = (calendarData) => {
    let newCalendarData = {};
    newCalendarData['calendar_id'] = calendarData['_id'].toString();
    newCalendarData['calendar'] = calendarData['calendar'];
    newCalendarData['owner_id'] = calendarData['owner_id'];

    console.log('newCalendarData', newCalendarData);

    this.setState({
      currentlySelectedCalendar: newCalendarData['calendar'],
      currentlySelectedCalendarId: newCalendarData['calendar_id'],
    });

    // return;

    db.collection("users").updateOne({
      "_id": {"$oid" : newCalendarData['calendar_id']},
      // "owner_id": "5e5661b132f3ef8c168ca73e",
    }, {
      "$set": newCalendarData
    }).then(() => {
      this.fetchUserCalendars();
    }).catch((err) => {

      console.log(err.message);

    });
  }

  addUserCalendar = (data) => {
    if(!data || !data.calendar || data.calendar == ''){

      return;
    }

    db.collection("users")
    .insertOne(data)
    .then((insertResult) => {

      // console.log('insertResult', insertResult);

      this.setState({
        currentlyEnteredCalendarName: '',
        openNewCalendarModal: false
      });
      this.updateUserCalendarAndFetchAllCalendars(data);
    });
  }

  fetchUserCalendars = (loadMainCalendar) => {
    // return;
    if(!this.state.currentCalendarUser){
      return;
    }
    return db.collection("users")
      .find({
      }, { limit: 1000 })
      .asArray()
      .then((responseJson) => {

        // console.log(responseJson);

        let allUserCalendars = [];
        let allUserCalendarsId = {};
        let mainCalendarId = '';
        allUserCalendars = responseJson.reduce((prev, cur) => {
          prev.push(cur.calendar);

          if(cur.calendar == 'main'){
            mainCalendarId = cur['calendar_id'];
          }

          allUserCalendarsId[cur.calendar] = cur['calendar_id'];

          return prev;
        }, []);
        allUserCalendars.push('all');

        // console.log('allUserCalendarsId', allUserCalendarsId);

        this.setState({
          allUserCalendars: allUserCalendars,
          allUserCalendarsId: allUserCalendarsId
        });

        if(loadMainCalendar){

          // console.log('Setting main calendar in calendar fetch');

          this.setState({
            currentlySelectedCalendarId: mainCalendarId,
            currentlySelectedCalendar: 'main'
          });
        }

        this.generateCalendarsDiv();
        this.fetchAllEvents();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onCalendarsSelect = (cur) => {
    if( typeof this.state.currentlySelectedCalendars[cur] == 'undefined' 
        || !this.state.currentlySelectedCalendars[cur] ){

          let thisCopy = this.state.currentlySelectedCalendars;

          thisCopy[cur] = true;

          console.log('setting copy', thisCopy);

          this.setState({
            currentlySelectedCalendars:thisCopy
          });
    } else {
      let thisCopy = this.state.currentlySelectedCalendars;
      thisCopy[cur] = false;

      this.setState({
        currentlySelectedCalendars:thisCopy
      });
    }

    this.fetchAllEvents();
  }

  handleCalendarCheckboxClick = (e) => {
    e.stopPropagation();

    this.setState({
      currentlySelectedCalendar: false,
      currentlySelectedCalendarId: false,
    });
  }

  generateCalendarsDiv = () => {
    return this.state.allUserCalendars.map((cur, index) => {
      let thisClass = '';
      if(cur == this.state.currentlySelectedCalendar){
        thisClass=" bg-blue-400"
      }
      let classNames = "bg-gray-100 shadow inline-block rounded p-1 pl-2 pr-2 mr-2 text-xs font-bold cursor-pointer" + thisClass;
      let curCursorCalendarId = this.state.allUserCalendarsId[cur];
      return (
        <div key={index} 
        className={classNames}
        onClick={() => {
          // console.log('setting new calendar as', cur);
          this.setState({
            currentlySelectedCalendar: cur,
            currentlySelectedCalendarId: this.state.allUserCalendarsId[cur],
            currentlySelectedCalendars: {}
          });
          localforage.setItem('tecaCalendarCurCalendar', {
            calendar: cur,
            calendar_id: this.state.allUserCalendarsId[cur],
          }).then(() => {
            this.fetchAllEvents();
          });
        }}>
          <span class="text-gray-700">{cur}</span>
          {
          cur && cur !== 'all' && <input class="ml-1" type="checkbox" checked={this.state.currentlySelectedCalendars[curCursorCalendarId] || false} 
          onClick={this.handleCalendarCheckboxClick}
          onChange={() => {
            this.onCalendarsSelect(curCursorCalendarId);
          }}></input>
          }
          {/* <button class="h-4 ml-1">
              <svg class="text-gray-700" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
              </svg>
          </button> */}
        </div>
      )
    });
  }

  generateColorCells = () => {
    let colors = ['darkgrey', 'darkolivegreen', 'darkcyan', 'darkviolet', 'darkorange'];
    return colors.map((color) => {
      let outline = 'none';
      if(this.state.currentlySelectedColor == color){
        outline = 'solid black';
      }
      return(
        <div 
        key={color} 
        style={{
          height: 30,
          width: 30,
          marginLeft: 3,
          marginRight: 3,
          float: 'left',
          background: color,
          outline: outline
        }} 
        onClick={() => {
          this.setState({
            currentlySelectedColor: color
          });
        }}></div>
      );
    });
  }

  generateSum = () => {
    let totalSum = 0;
    
    Object.keys(this.state.allEvents).forEach((curr) => {
      let monthYear = this.state.currentlyViewedYearNum + '-' + (this.state.currentlyViewedMonthNum + 1);
      // console.log('monthYear', monthYear);
      if(curr.indexOf(monthYear) >= 0){
        
        this.state.allEvents[curr].map((cur) => {
          if(!cur.event || cur.event.indexOf('|') < 0){
            
            return true;
          }
          let currentMoney = cur.event.split('|').pop();
          totalSum = totalSum + parseInt(currentMoney);

          return totalSum;
        });
      }
    });

    if(totalSum == 0){

      return '';
    }

    return (
      <button class="bg-gray-200 shadow p-1 ml-1 text-sm font-bold rounded text-gray-800">
        <div style={{
          minWidth: 100
        }}>
          TOTAL : {totalSum}
        </div>
      </button>
    );
  }

  renderDeleteCalendarModal = () => {
    return (
    
    <div class="absolute w-full h-full top-0 pt-24 pl-2 pr-2" style={{
      backgroundColor: "rgba(0,0,0,0.5)"
    }}>
      <div class="sm:w-full md:w-1/2 lg:w-5/12 x1:w-3/4 xl-1/4 h-auto bg-gray-200 shadow rounded m-auto">
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="mb-6">
              <div class="block text-gray-700 text-sm font-bold mb-2" for="calendar_event">
                Are you sure you want to delete the caldendar 
                <span class="text-blue-400"> {this.state.currentlySelectedCalendar}.</span>
              </div>
              <div class="bg-yellow-200 m-1 p-2 shadow-sm rounded">
                <span class="text-sm">Note: This will also delete all the events in the calendar.</span>
              </div>
          </div>

          <div class="flex items-center justify-between">
              <button class="bg-red-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm" 
              type="button" onClick={() => {
                this.deleteThisCalendar(this.state.currentlySelectedCalendar);
              }} >
              Delete
              </button>
              <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 text-sm" onClick={() => {
                this.setState({
                  openDeleteCalendarModal: false
                });
              }}>
                Cancel
              </a>
          </div>
        </div>
      </div>
    </div>
    )
  }

  render() {
    let allDates = calendarize(this.state.currentDate, 1);

    // console.log(allDates);

    let leftSymbol = '<';
    let rightSymbol = '>';

    let currentInput = this.state.currentInput;
    let currentInputNumber = this.state.currentInputNumber;

    return (
      <div>
        {
          !this.state.currentCalendarUser && !this.state.showLoginDiv && this.showEmptyIconScreen()
        }
        { 
          !this.state.currentCalendarUser && this.state.showLoginDiv && this.generateLoginScreen()
        }
        { this.state.currentCalendarUser && this.state.openModal && 
        <div class="sm:w-full md:w-1/2 bg-blue-300 shadow m-auto md:mt-16 sm:mt-0 p-4 md:h-auto h-screen rounded">
          <div class="m-1 p-1 font-bold text-center text-gray-800"> Events : {this.state.currentlySelectedCalendar} </div>
          <div class="overflow-y-auto p-1 mb-2" style={{
            height: "52vh",
          }}>
            { this.state.allEvents[this.state.currentDateStr] && 
              this.generateCellEventsBig(this.state.allEvents[this.state.currentDateStr])
            }
          </div>
          {
            this.state.currentlySelectedCalendar && 
            <div class="w-full block float-left p-1 mb-3">
              {this.generateColorCells()}
            </div>
          }
          {
            this.state.currentlySelectedCalendar && 
            <div class="p-1 w-11/12 mb-4 float-left">
              <textarea
                class="w-full bg-gray-200 p-1 outline-none rounded-sm text-sm"
                placeholder="Enter new event"
                onChange={(e) => {
                  this.setState({
                    currentInput: e.target.value
                  });
                }} 
                value={currentInput}
              />
            </div>
          }
          {
            this.state.currentlySelectedCalendar && 
            <div class="w-1/12 float-left p-2 pt-4">
              <button class="bg-gray-200 rounded-sm" onClick={() => {
                this.setState({
                  showNumberBox: !this.state.showNumberBox
                });
              }}>
                { !this.state.showNumberBox && <svg class="h-4 inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg> }
                { this.state.showNumberBox && <svg class="h-4 inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg>}
              </button>
            </div>
          }
          { 
            this.state.showNumberBox && 
            <div class="p-1 w-full mb-4 float-left">
              <input
                type="number"
                class="w-1/4 bg-gray-200 p-1 outline-none rounded-sm text-xs"
                placeholder="Enter amount"
                onChange={(e) => {
                  this.setState({
                    currentInputNumber: e.target.value
                  });
                }} 
                value={currentInputNumber}
              />
            </div>
          }
          <div class="clear"></div>
          <div class="w-full mt-2">
            { 
              !this.state.currentlyEditedEventId && this.state.currentlySelectedCalendar != 'all' && 
             <button class="bg-blue-600 p-2 pl-4 pr-4 rounded shadow text-gray-200 font-bold text-sm" 
                onClick={() => {
                let data = {
                  dateStr: this.state.currentDateStr,
                  eventStr: this.state.currentInput,
                  eventNumber: this.state.currentInputNumber,
                  eventColor: this.state.currentlySelectedColor,
                  calendar: this.state.currentlySelectedCalendar,
                  calendar_id: this.state.allUserCalendarsId[this.state.currentlySelectedCalendar],
                  owner_id: this.state.currentCalendarUser
                };

                // console.log(data);

                this.addNewEvent(data);
                this.setState({
                  openModal: false
                });
              }}>
                <div style={{
                  textAlign: 'center'
                }}>Save</div>
              </button> 
            }
            { 
              this.state.currentlyEditedEventId && 
              <button class="bg-yellow-700 p-2 pl-4 pr-4 rounded shadow text-gray-200 font-bold text-sm" 
              onClick={() => {
                let data = {
                  dateStr: this.state.currentDateStr,
                  eventStr: this.state.currentInput,
                  eventNumber: this.state.currentInputNumber,
                  eventColor: this.state.currentlySelectedColor,
                  calendar: this.state.currentlySelectedCalendar,
                  calendar_id: this.state.currentlySelectedCalendarId,
                  owner_id: this.state.currentCalendarUser
                };

                // console.log(data);

                this.editEvent(data);
                this.setState({
                  openModal: false
                });
              }}>
                <div style={{
                  textAlign: 'center'
                }}>Update</div>
              </button> 
            }
            <button class="bg-gray-200 p-2 pl-4 pr-4 rounded shadow text-gray-700 font-bold text-sm float-right" 
              onClick={() => {
                this.setState({
                  openModal: false,
                  currentlyEditedEventId: false
                });
            }}>
              <div style={{
                textAlign: 'center'
              }}>Cancel</div>
            </button>
          </div>
          <div class="clear-both"></div>
        </div>
        
        }
        { this.state.currentCalendarUser && !this.state.openModal && 
          <div class="bg-blue-300" style={{
              // backgroundColor: 'lightsteelblue',
              overflow: 'hidden'
            }}>

            <div style={{
              height: '6vh'
            }} class="tec_sec_1 w-full h-11 bg-blue-300 p-1">
                <button class="text-center align-middle h-full opacity-75 float-left hidden" onClick={this.toggleDrawer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="opacity-75">
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </button>
                {this.generateSum()}
                <button class="bg-blue-600 shadow p-1 pl-2 pr-2 m-1 ml-2 text-sm font-bold text-gray-100 rounded outline-none" onClick={() => {
                  localforage.setItem('tecaCalendarShowCal', !this.state.showCalendars);
                  this.setState({
                    showCalendars: !this.state.showCalendars
                  });
                }}>
                  <span>{this.state.currentlySelectedCalendar}</span>
                  { !this.state.showCalendars && <svg class="h-4 inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg> }
                  { this.state.showCalendars && <svg class="h-4 inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg> }
                </button>
                <button class="ml-1 mt-1 bg-blue-500 text-sm rounded float-right text-white font-bold px-2 py-1" 
                onClick={() => {
                  localforage.setItem('tecaCalendarUser', false);
                  localforage.setItem('tecaCalendarCurCalendar', false);

                  this.setState({
                    currentCalendarUser: false,
                    showLoginDiv: true
                  });
                }}>
                  { this.renderLogoutIcon() }
                </button>
            </div>

            { this.state.showCalendars && <div style={{
              width: '100%',
              overflow: 'auto',
              position: 'absolute',
              backgroundColor: '#b7def8'
            }}>
              <div className="p-1 ml-2" style={{
                width: '700%'
              }}>
                { this.generateCalendarsDiv() }
                <div class="inline-block bg-blue-600 rounded p-1 pl-2 pr-2 shadow text-xs outline-none" onClick={() => {
                      this.setState({
                        openNewCalendarModal: true
                      });
                }}>
                    { this.renderPlusButton() }
                    <span class="text-xs font-bold text-gray-100 ml-1 cursor-pointer outline-none">Create New Calendar</span>
                </div>
                {
                  this.state.currentlySelectedCalendar &&
                  <div class="inline-block bg-red-600 rounded p-1 pl-2 pr-2 ml-2 shadow text-xs outline-none" onClick={() => {
                        this.setState({
                          openDeleteCalendarModal: true
                        });
                  }}>
                      { this.renderDeleteButton() }
                      <span class="text-xs font-bold text-gray-100 ml-1 cursor-pointer">Delete Selected Calendar</span>
                  </div>
                }
              </div>
            </div> }

            <div className="tec_sec_2" style={{
              display: 'flex',
              flexDirection: 'row',
              height: '6vh'
            }}>
              { this.generateRowHeader() }
            </div>
            <div className="tec_sec_4" style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              { this.generateRow(allDates) }
            </div>

            <div className="tec_sec_3" style={{
              height: '6vh',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <button 
                style={{
                  flex: 0.2,
                  fontWeight: 'bold',
                  fontSize: 14,
                  paddingTop: 5,
                  height: 40
                }}
                onClick={() => {
                  let tempDateObj = this.state.currentDate;
                  tempDateObj.setMonth(this.state.currentlyViewedMonthNum - 1);
                  this.setState({
                    currentDate: tempDateObj,
                    currentDateNum: 1,
                    currentlyViewedMonthNum: tempDateObj.getMonth(),
                    currentlyViewedYearNum: tempDateObj.getFullYear(),
                    currentlyViewedMonth: tempDateObj.toLocaleString('default', { month: 'short' }),
                  });
                  // console.log(this.state.allEvents);
                }}>
                {leftSymbol}
              </button>
              <div style={{
                flex: 0.6,
                paddingTop: 15
              }}>
                <div style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  marginBottom: 20,
                  textAlign: 'center'
                }}>{this.state.currentlyViewedMonth}
                  <input class="ml-1 pl-1 appearance-none w-16 shadow rounded text-sm font-bold" type="text" onChange={(e) => {
                    if(e.target.value < 1000){
                      this.setState({
                        currentlyViewedYearNum: e.target.value,
                      });

                      this.fetchAllEvents();

                      return;
                    }
                    let newDateStr = this.getCurrentDateStrByYear(e.target.value);
                    let newDateObj = new Date(newDateStr);
                    this.setState({
                      currentlyViewedYearNum: e.target.value,
                      currentDate: newDateObj
                    });
                  }} value={this.state.currentlyViewedYearNum}></input>
                </div>
              </div>
              <button 
              style={{
                flex: 0.2,
                fontWeight: 'bold',
                fontSize: 14,
                height: 40
              }}
              onClick={() => {
                let tempDateObj = this.state.currentDate;
                tempDateObj.setMonth(this.state.currentlyViewedMonthNum + 1);
                this.setState({
                  currentDate: tempDateObj,
                  currentDateNum: 1,
                  currentlyViewedYearNum: tempDateObj.getFullYear(),
                  currentlyViewedMonthNum: tempDateObj.getMonth(),
                  currentlyViewedMonth: tempDateObj.toLocaleString('default', { month: 'short' }),
                });
              }}>
               {rightSymbol}
              </button>
            </div>

            { this.state.openNewCalendarModal && 
              <div class="absolute w-full h-full top-0 pt-24 pl-2 pr-2" style={{
                backgroundColor: "rgba(0,0,0,0.5)"
              }}>
                <div class="sm:w-full md:w-1/2 h-auto bg-gray-200 shadow rounded m-auto">
                  <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="calendar_event">
                        Create New Calendar
                        </label>
                        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                          id="calendar_event" type="text" placeholder="Enter New Calendar" 
                          onChange={(e) => {
                            this.setState({
                              currentlyEnteredCalendarName: e.target.value
                            });
                          }} value={this.state.currentlyEnteredCalendarName}
                        />
                    </div>

                    <div class="flex items-center justify-between">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                        type="button" onClick={() => {
                          let data = {
                            owner_id: this.state.currentCalendarUser,
                            calendar: this.state.currentlyEnteredCalendarName
                          };
                          this.addUserCalendar(data);
                        }} >
                        Save
                        </button>
                        <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" onClick={() => {
                          this.setState({
                            openNewCalendarModal: false
                          });
                        }}>
                          Cancel
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            }

            { this.state.openDeleteCalendarModal && this.renderDeleteCalendarModal() }

          </div>
        }
      </div>
    )
  }
}

export default class App extends Component {
  render() {
    return (
      <div style={{
        // background: 'lightsteelblue'
      }}>
        <MyCalendar />
      </div>
    )
  }
}
